
<template>
    <v-container fluid class="main_bg pa-0 abbas_space" :class="$vuetify.breakpoint.xl ? 'abbas_margin' : ''" :style="loading ? '' : gradiant_style">
       <!-- <div class="d-none d-lg-flex">
        <BreakingNews v-if="isLang"></BreakingNews>
       </div> -->
            <v-layout row class="nav_opacity pa-0 ma-0 py-2" :style="{backgroundColor: nvColor}">
                <v-layout row class="mx-auto pt-4 pb-2" style="max-width: 1261px" >
                    <v-flex lg6 align-self-center class="px-1 messiri_fonted" :class="dir === 'rtl' ? 'text-right' : 'text-left'"> 
                        <template v-if="loading">
                            <v-skeleton-loader  v-bind="attrs" type="text@1" height="10" max-width="325" style="top:10px"></v-skeleton-loader>
                        </template>
                        <template v-else >
                            <img src="/assets/img/date-green.svg" style="margin:0 8px">
                            <span class="hidden-sm-and-down" v-text="dateD+' -'"></span> 
                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                            <span class="hidden-md-and-up" v-if="dateH_ico" v-text="dateH_ico"></span> 
                            <span class="hidden-md-and-up" v-else v-text="dateM"></span> 
                            <span class="hidden-sm-and-down" v-text="dateM"> </span> 
                        </template>
                    </v-flex>
                    <v-flex lg6 :class="dir === 'rtl' ? 'text-left' : 'text-right'">
                        <div v-if="$i18n.locale !== 'pr'" class="alkafeel_facebook_icon"  @click="targrtLink(fbUrl)">
                            <svg version="1.1" id="face" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 430.113 430.114" style="enable-background:new 0 0 430.113 430.114;" xml:space="preserve"><g><path id="Facebook" d="M158.081,83.3c0,10.839,0,59.218,0,59.218h-43.385v72.412h43.385v215.183h89.122V214.936h59.805 c0,0,5.601-34.721,8.316-72.685c-7.784,0-67.784,0-67.784,0s0-42.127,0-49.511c0-7.4,9.717-17.354,19.321-17.354 c9.586,0,29.818,0,48.557,0c0-9.859,0-43.924,0-75.385c-25.016,0-53.476,0-66.021,0C155.878-0.004,158.081,72.48,158.081,83.3z"/></g></svg>
                        </div>
                        <div v-else class="alkafeel_eitaa_icon"  @click="targrtLink(fbUrl)">
                            <svg xmlns="http://www.w3.org/2000/svg" id="face" xml:space="preserve"  version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 130000 133000"><path d="M37096.47 4.39l55624.18 0c20395.81,0 37082.9,16661.88 37082.9,37057.36l0 17884.55c-18284.34,8220.89 -36730.61,48812.96 -63615.28,39992.34 -2214.56,1573.65 -7318.2,8058.06 -7677.6,12978.25 -9309.43,-1239.76 -20041.9,-11908.47 -18753.95,-23413.06 -15492.55,-11207.9 -2698.07,-31897.82 9589.32,-40225.36 26334.91,-17848.35 62895.52,-2498.01 42558.86,10263.51 -12365.92,7759.68 -38809.75,12885.62 -36060.3,-6164.38 -7253.88,2092.39 -11897.28,15618.27 -3163.47,22665.63 -8090.64,7949.08 -6535.41,22560.27 2113.08,27357.84 8746.41,-22661.43 39188.54,-19699.96 51489.49,-46752.05 9253.84,-20350.23 -4465.64,-43535.57 -31896.19,-39973.27 -20703.17,2688.7 -40107.72,20151.47 -49812.26,40863.04 -9846.72,21015.05 -8381.2,49152.99 11837.65,63334.75 23793.92,16689.18 49126.59,1235.84 64469.5,-18961.45 9043.43,-11904.72 16945.65,-25094.15 28921.15,-32709.37l0 31708.1c0,20395.38 -16687.42,37108.3 -37082.9,37108.3l-55624.18 0c-20395.81,0 -37083.09,-16687.29 -37083.09,-37083.09l0 -58848.75c0,-20395.8 16687.28,-37082.89 37083.09,-37082.89z"/></svg>
                        </div>
                        <div class="alkafeel_twitter_icon"   @click="targrtLink(twUrl)">
                            <svg viewBox="0 0 24 24" aria-hidden="true" ><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
                        </div>
                        <div class="alkafeel_telegram_icon"  @click="targrtLink(teUrl)"><svg version="1.1" id="telegram" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><g id="XMLID_496_"><path id="XMLID_497_" d="M5.299,144.645l69.126,25.8l26.756,86.047c1.712,5.511,8.451,7.548,12.924,3.891l38.532-31.412 c4.039-3.291,9.792-3.455,14.013-0.391l69.498,50.457c4.785,3.478,11.564,0.856,12.764-4.926L299.823,29.22 c1.31-6.316-4.896-11.585-10.91-9.259L5.218,129.402C-1.783,132.102-1.722,142.014,5.299,144.645z M96.869,156.711l135.098-83.207 c2.428-1.491,4.926,1.792,2.841,3.726L123.313,180.87c-3.919,3.648-6.447,8.53-7.163,13.829l-3.798,28.146 c-0.503,3.758-5.782,4.131-6.819,0.494l-14.607-51.325C89.253,166.16,91.691,159.907,96.869,156.711z"/></g></svg></div>
                        <div class="alkafeel_instagram_icon" @click="targrtLink(isUrl)"><svg version="1.1" id="instagram" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 169.063 169.063" style="enable-background:new 0 0 169.063 169.063;" xml:space="preserve"><g><path d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752 c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407 c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752 c17.455,0,31.656,14.201,31.656,31.655V122.407z"/><path d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561 C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561 c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"/><path d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78 c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78  C135.661,29.421,132.821,28.251,129.921,28.251z"/></g></svg></div>
                        
                        <div class="alkafeel_y-tube_icon"  v-if="$i18n.locale === 'pr'"  @click="targrtLink(ytUrl)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><path d="M24,8C15.163,8,8,15.163,8,24s7.163,16,16,16s16-7.163,16-16S32.837,8,24,8z M20,13  c2.209,0,4,1.791,4,4c0,2.209-1.791,4-4,4s-4-1.791-4-4C16,14.791,17.791,13,20,13z M17,32c-2.209,0-4-1.791-4-4  c0-2.209,1.791-4,4-4s4,1.791,4,4C21,30.209,19.209,32,17,32z M22,24c0-1.105,0.895-2,2-2s2,0.895,2,2c0,1.105-0.895,2-2,2  S22,25.105,22,24z M28,35c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4C32,33.209,30.209,35,28,35z M31,24  c-2.209,0-4-1.791-4-4c0-2.209,1.791-4,4-4s4,1.791,4,4C35,22.209,33.209,24,31,24z"/></svg>
                        </div>
                        <div class="alkafeel_y-tube_icon" v-else  @click="targrtLink(ytUrl)">
                            <svg version="1.1" id="youtube" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 310 310" style="enable-background:new 0 0 310 310;" xml:space="preserve"><g id="XMLID_822_"><path id="XMLID_823_" d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938 C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527 C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991 c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764 c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861 C204.394,157.263,202.325,160.684,199.021,162.41z"/></g></svg>
                        </div>
                        <div class="alkafeel_tiktok_icon"    @click="targrtLink(tkUrl)"><svg version="1.1" id="tiktok" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"><path fill="#FF004F" d="M58,19.4v9.3c-0.5,0-1.1,0.1-1.7,0.1c-4.5,0-8.7-1.7-11.9-4.4v19.8c0,4-1.3,7.8-3.6,10.8     c-3.2,4.3-8.4,7.2-14.3,7.2c-6.4,0-12-3.4-15.1-8.4c3.2,3,7.5,4.9,12.2,4.9c5.8,0,11-2.8,14.2-7.2c2.2-3,3.6-6.7,3.6-10.8V20.8     c3.2,2.8,7.3,4.4,11.9,4.4c0.6,0,1.1,0,1.7-0.1v-6c0.9,0.2,1.7,0.3,2.6,0.3H58z"/><path fill="#FF004F" d="M29,26.3v10.3c-0.7-0.2-1.5-0.3-2.2-0.3c-4.4,0-8,3.7-8,8.2c0,1,0.2,1.9,0.5,2.8c-2-1.5-3.4-3.9-3.4-6.6     c0-4.5,3.6-8.2,8-8.2c0.8,0,1.5,0.1,2.2,0.3l0-6.6c0.2,0,0.4,0,0.6,0C27.5,26.2,28.3,26.2,29,26.3z"/><path fill="#FF004F" d="M45.9,12c-1.8-1.6-3.1-3.8-3.8-6.1h2.4c0,0.5,0,0.9,0,1.4C44.7,8.9,45.2,10.5,45.9,12z"/><path d="M55.1,19.2v6c-0.5,0.1-1.1,0.1-1.7,0.1c-4.5,0-8.7-1.7-11.9-4.4v19.8c0,4-1.3,7.8-3.6,10.8c-3.3,4.4-8.4,7.2-14.2,7.2   c-4.7,0-9-1.9-12.2-4.9c-1.7-2.8-2.7-6-2.7-9.5c0-9.7,7.7-17.6,17.3-17.9l0,6.6c-0.7-0.2-1.5-0.3-2.2-0.3c-4.4,0-8,3.7-8,8.2   c0,2.7,1.3,5.2,3.4,6.6c1.1,3.1,4.1,5.4,7.5,5.4c4.4,0,8-3.7,8-8.2V5.9h7.3c0.7,2.4,2,4.5,3.8,6.1C47.7,15.6,51.1,18.3,55.1,19.2z"/><path fill="#00F7EF" d="M26.1,22.8l0,3.4c-9.6,0.3-17.3,8.2-17.3,17.9c0,3.5,1,6.7,2.7,9.5C8.1,50.3,6,45.7,6,40.5      c0-9.9,8-17.9,17.8-17.9C24.6,22.6,25.4,22.7,26.1,22.8z"/><path fill="#00F7EF" d="M42.1,5.9h-7.3v38.6c0,4.5-3.6,8.2-8,8.2c-3.5,0-6.4-2.2-7.5-5.4c1.3,0.9,2.9,1.5,4.6,1.5      c4.4,0,8-3.6,8-8.1V2h9.7v0.2c0,0.4,0,0.8,0.1,1.2C41.7,4.2,41.9,5.1,42.1,5.9z"/><path fill="#00F7EF" d="M55.1,15.5C55.1,15.5,55.1,15.5,55.1,15.5v3.6c-4-0.8-7.4-3.5-9.3-7.1C48.3,14.3,51.5,15.6,55.1,15.5z"/></svg></div>
                        
                        <span class="hidden-sm-and-down" style="margin:5px 15px;vertical-align: text-bottom;">|</span> 
                        <v-btn width="15" class="hidden-sm-and-down mx-3 mt-n2 togg_btn" icon @click="toggle_dark_mode()" >
                            <svg v-if="$vuetify.theme.dark" width="15px" viewBox="0 0 15 15" fill="#FFF"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                            <svg v-else viewBox="0 0 15 15" width="15px" fill="#FFF"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                        </v-btn>
                    </v-flex>
                </v-layout>


            </v-layout>
            <div class="border" ></div>

            

            <v-layout row class="mx-auto" style="max-width:1261px"></v-layout>

                <v-layout row justify-center class="mx-auto">
                    <v-app-bar max-width="1261" :height="!isLang() ? '62' : '52'" app dense elevate-on-scroll elevation="5" flat :color="nvColor" class="hidden-sm-and-down navbar mx-auto">
                        <v-toolbar-title class="height" style="padding-top: 2px">
                            <router-link class="height" :to="'/?lang='+lang">
                                <v-img contain :max-height="!isLang() ? '60' : '50'" min-width="200" :position="dir === 'rtl' ? 'right center' : 'left center'" :src="'https://alkafeel.net/src/svg/'+logo" alt=""></v-img>
                            </router-link>
                        </v-toolbar-title>
                        <v-toolbar-items>
                            <template v-if="loading">
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                                <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="button"></v-skeleton-loader>
                            </template>
                            <template v-else >
                                <div v-for="item in nav.slice(0, 6)" :key="item">  
                                    <template v-if="item.width < 790">  
                                        <v-menu offset-y offset-overflow left  close-on-content-click="false" open-delay="500">
                                            
                                            <template v-slot:activator="{ on, attrs }" v-if="item.visible == 1">
                                                <!-- if arabic -->
                                                <template v-if="!isLang()">

                                                    <v-btn style="margin-top: 10px;" :to="item.router !== '0' ? item.to : ''" :href="item.router === '0' ? item.url : ''"  class="nav_btn" color="rgba(0,0,0,0)" :title="item.title" v-bind="attrs" v-on="on">
                                                        <span class="text-light textspace">{{ item.title }}</span>
                                                        <span v-if="item.sub" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                    </v-btn>

                                                </template>
                                                <!-- if is lang -->
                                                <template v-else>
                                                    
                                                        <v-btn :to="item.router !== '0' ? (item.to ? item.to+'?lang='+lang : '') : ''" :href="item.router === '0' ? (item.url ? item.url+'?lang='+lang : '') : ''" style="margin-top: 6px;" class="nav_btn" color="rgba(0,0,0,0)" :title="item.title" v-bind="attrs" v-on="on" v-if="item.aronly == 0">
                                                            <span class="textspace">{{ $t(item.word) }}</span>
                                                            <span v-if="item.sub" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                        </v-btn>

                                                </template>
                                            </template>
                                            
                                            <v-list class="messiri_fonted" color="rgba(255,255,255,.8)" v-if="item.sub" :width="item.width">
                                                
                                                    <template v-for="(t, index) in JSON.parse(item.sub)" >
                                                        <!-- one col subs -->
                                                        <template v-if="item.width < 370 && t.visible == 1">
                                                            <template v-if="!isLang()">
                                                                <v-list-item class="list_height" :key="index">
                                                                    <v-list-item-title >

                                                                        <template v-if="!t.router">
                                                                            <a v-if="!t.sub" class="black--text sub_nav" :href="t.url"><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ t.title }} </a>
                                                                            
                                                                            
                                                                            
                                                                            <v-menu offset-y v-else offset-overflow left  close-on-content-click="false" v-model="menuOpen">
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <v-list-item v-bind="attrs" v-on="on">
                                                                                    <v-list-item-title>
                                                                                        <a class="black--text sub_nav"><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ t.title }} </a>
                                                                                    </v-list-item-title>
                                                                                    <v-icon class="black--text">mdi-chevron-down</v-icon>
                                                                                    </v-list-item>
                                                                                </template>
                                                                            
                                                                                <v-list>
                                                                                    <v-list-item v-for="(item, index) in t.sub" :key="index">
                                                                                    <v-list-item-title>
                                                                                        <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ item.title }} 
                                                                                    </v-list-item-title>
                                                                                    </v-list-item>
                                                                                </v-list>
                                                                            </v-menu>
                                                                            

                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="black--text sub_nav" :to="t.to" ><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ t.title }} </router-link>
                                                                        </template>
                                                                        
                                                                    </v-list-item-title>
                                                                </v-list-item>


                                                            </template>
                                                            <template v-else-if="t.aronly == 0">
                                                                <v-list-item class="list_height" :key="index">
                                                                    <v-list-item-title>

                                                                        <template v-if="!t.router">
                                                                            <a class="black--text sub_nav" :href="t.url+'?lang='+lang"> <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ $t(t.word)}} </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="black--text sub_nav" :to="t.to+'?lang='+lang" ><span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> {{ $t(t.word) }} </router-link>
                                                                        </template>
                                                                        
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </template>
                                                        </template>
                                                        
                                                        <!-- two col subs -->
                                                        <template v-if="item.width > 370 && item.width < 790">
                                                            <!-- if arabic -->
                                                            <template v-if="!isLang()">
                                                                <v-list-item class="list_height" v-if="index%2 == 0" :key="index">
                                                                    <v-flex lg6>

                                                                        <template v-if="!t.router">
                                                                            <a class="black--text sub_nav" :href="t.url" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ t.title }}
                                                                            </a> 
                                                                        </template> 
                                                                        <template v-else>
                                                                            <router-link class="black--text sub_nav" :to="t.to" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ t.title }} 
                                                                            </router-link>
                                                                        </template>
                                                                        
                                                                    </v-flex>
                                                                    <v-flex lg6 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1"> 
                                                                    
                                                                        <template v-if="!JSON.parse(item.sub)[index+1].router">
                                                                            <a class="black--text sub_nav" :href="JSON.parse(item.sub)[index+1].url">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ JSON.parse(item.sub)[index+1].title }}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="black--text sub_nav" :to="JSON.parse(item.sub)[index+1].to">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ JSON.parse(item.sub)[index+1].title }}
                                                                            </router-link>
                                                                        </template>

                                                                    </v-flex>
                                                                </v-list-item>
                                                            </template>
                                                            <!-- if is lang -->
                                                            <template v-else-if="t.aronly == 0">
                                                                <v-list-item class="list_height" v-if="index%2 == 0" :key="index">
                                                                    <v-flex lg6 >

                                                                        <template v-if="!t.router">
                                                                            <a class="black--text sub_nav" :href="t.url+'?lang='+lang" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(t.word) }}
                                                                            </a> 
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="black--text sub_nav" :to="t.to+'?lang='+lang" v-if="t.visible == 1">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(t.word) }}
                                                                            </router-link>
                                                                        </template>
                                                                        
                                                                    </v-flex>
                                                                    <v-flex lg6 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1"> 

                                                                        <template v-if="!JSON.parse(item.sub)[index+1].router">
                                                                            <a class="black--text sub_nav" :href="JSON.parse(item.sub)[index+1].url+'?lang='+lang">
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(JSON.parse(item.sub)[index+1].word) }}
                                                                            </a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <router-link class="black--text sub_nav" :to="JSON.parse(item.sub)[index+1].to+'?lang='+lang" >
                                                                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                                {{ $t(JSON.parse(item.sub)[index+1].word) }}
                                                                            </router-link>
                                                                        </template>

                                                                    </v-flex>
                                                                </v-list-item>
                                                            </template> 

                                                        </template>

                                                        <!-- three col subs *** canceled-->
                                                        <template v-if="item.width > 790">
                                                            <v-list-item class="list_height" v-if="index%3 == 0" :key="index">
                                                                <v-flex lg4> 
                                                                    <a class="white--text sub_nav" target="_blank" :href="t.url" v-if="t.visible == 1">{{ t.title }}</a> 
                                                                </v-flex>
                                                                <v-flex lg4 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1"> 
                                                                    <a class="white--text sub_nav" target="_blank" :href="JSON.parse(item.sub)[index+1].url">{{ JSON.parse(item.sub)[index+1].title }}</a> 
                                                                </v-flex>
                                                                <v-flex lg4 v-if="JSON.parse(item.sub)[index+2] && JSON.parse(item.sub)[index+2].visible == 1"> 
                                                                    <a class="white--text sub_nav" target="_blank" :href="JSON.parse(item.sub)[index+2].url">{{ JSON.parse(item.sub)[index+2].title }}</a> 
                                                                </v-flex>
                                                            </v-list-item>

                                                        </template>
                                                    </template>

                                            </v-list>

                                        </v-menu>
                                    </template>

                                    
                                    <template v-else>
                                        <template v-if="!isLang()">
                                             <template v-if="item.visible == 1">
                                                    <v-btn elevation="0" @click="relatedOverlay = !relatedOverlay" style="margin-top: 10px;" class="nav_btn" color="rgba(0,0,0,0)" :title="item.title" v-bind="attrs" v-on="on">
                                                        <a class="text-light textspace" :href="item.url"> {{ item.title }} </a>
                                                        <!-- <v-icon v-if="item.sub"> mdi-chevron-down </v-icon> -->
                                                        <span style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                    </v-btn>
                                                    
                                             </template>
                                        </template>
                                    </template>

                                </div>
                            </template>
                        </v-toolbar-items>
                    </v-app-bar>

                    
                    <v-app-bar app elevate-on-scroll elevation="5" flat :color="nvColor" class="hidden-md-and-up mx-auto" :class="drawer ? 'navbar_m_active' : 'navbar_m'">
                        <v-toolbar-title class="height">
                            <router-link class="height" :to="'/?lang='+lang">
                                <v-img dark contain height="50" :max-width="isLang() ? '200' : ''" :position="dir === 'rtl' ? 'right center' : 'left center'" :src="'https://alkafeel.net/src/svg/'+logo" alt=""></v-img>
                            </router-link>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>


                        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text"></v-app-bar-nav-icon> -->
                        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="white--text">
                            <div style="fill:#FFF; width:30px; margin-top: 5px"><svg viewBox="0 0 35 25"><use xlink:href="/assets/img/burger_menu.svg#view"></use></svg></div>
                        </v-app-bar-nav-icon>

                        <v-navigation-drawer style="padding: 20px" app class="drawer" :color="$vuetify.theme.dark ? '#292c2c' : '#FFF'" temporary disable-resize-watcher v-model="drawer" absolute :right="dir === 'rtl' ? true : false">
                            <v-list nav dense>
                                <v-list-item-group v-model="group" active-class="text--accent-4" >

                                    <v-list-item-title style="margin-bottom: 20px">
                                        <v-img contain :height="dir === 'ltr' ? '30' : '50'" :position="dir === 'rtl' ? 'right center' : 'left center'" alt="" :src="!isLang() ? 'https://alkafeel.net/src/svg/'+ar_logo : 'https://alkafeel.net/'+lang_src+'/'+logo">
                                            <div class="fill-height" :style="dir === 'rtl' ? 'text-align: left' : 'text-align: right'">
                                                <img height="20" style="cursor:pointer;" src="/assets/img/t-mnue.svg" @click.stop="drawer = false">
                                            </div>
                                        </v-img>
                                    </v-list-item-title>

                                    <template v-for="item in nav">
                                        <template v-if="!isLang()">
                                            <!-- :append-icon="item.sub ? ' mdi-chevron-down' : ''" -->
                                            <v-list-group :append-icon="false" :value="false" :key="item">
                                                <template v-slot:activator>
                                                    <v-list-item-title v-if="item.visible == 1" :title="item.title">

                                                        <template v-if="item.router == 0">
                                                            <a class="navMColor navSize" :href="item.url" >{{ item.title }}</a>
                                                        </template>
                                                        <template v-else>
                                                            <router-link class="navMColor navSize" :to="item.to">{{ item.title }}</router-link>
                                                        </template>
                                                        
                                                    </v-list-item-title>
                                                </template>

                                                <v-list-group class="messiri_fonted" :append-icon="false" v-for="(t, index) in JSON.parse(item.sub)" :key="index">
                                                    <template v-slot:activator>
                                                        <div v-if="item.sub">

                                                            <template v-if="!t.router">
                                                                <a class="navMColor sub_nav_m" :href="t.url" v-if="t.visible == 1"> 
                                                                    <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                    {{ t.title }} 
                                                                </a>
                                                            </template>
                                                            <template v-else>
                                                                <router-link class="navMColor sub_nav_m" :to="t.to" v-if="t.visible == 1">
                                                                    <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                    {{ t.title }}
                                                                </router-link>
                                                            </template>

                                                        </div>
                                                    </template>
                                                </v-list-group>

                                                
                                                <span v-if="item.sub" slot="appendIcon" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                                

                                            </v-list-group>
                                        </template>

                                        <template v-else-if="item.aronly == 0">
                                            <!-- :append-icon="item.sub ? ' mdi-chevron-down' : ''" -->
                                            <v-list-group :append-icon="false" :value="false" :key="item">
                                                <template v-slot:activator>
                                                    <v-list-item-title v-if="item.visible == 1" :title="$t(item.word)">

                                                        <template v-if="item.router == 0">
                                                            <a class="navMColor navSize" :href="item.url ? item.url+'?lang='+lang : null" >{{ $t(item.word) }}</a>
                                                        </template>
                                                        <template v-else>
                                                            <router-link class="navMColor navSize" :to="item.to ? item.to+'?lang='+lang : null">{{ $t(item.word) }}</router-link>
                                                        </template>
                                                        
                                                    </v-list-item-title>
                                                </template>

                                                <template v-for="t in JSON.parse(item.sub)" >
                                                    <template v-if="t.aronly == 0">
                                                        <v-list-group class="messiri_fonted" :append-icon="false" :key="t" v-if="item.sub && t.visible == 1">
                                                            <template v-slot:activator>
                                                                
                                                                <template v-if="!t.router">
                                                                    <a class="navMColor sub_nav_m" :href="t.url+'?lang='+lang">
                                                                        <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                        {{ $t(t.word) }} 
                                                                    </a>
                                                                </template>
                                                                <template v-else>
                                                                    <router-link class="navMColor sub_nav_m" :to="t.to+'?lang='+lang" >
                                                                        <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span> 
                                                                        {{ $t(t.word) }}
                                                                    </router-link>
                                                                </template>

                                                            </template>
                                                        </v-list-group>
                                                    </template>
                                                </template>

                                                <span v-if="item.sub" slot="appendIcon" style="content: url(/assets/img/arrow_down.svg); width:10px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                                            </v-list-group>
                                        </template>

                                    </template>



                                    <v-card dark flat>
                                        <v-toolbar flat style="padding-top:10px">
                                            <v-switch :input-value="$vuetify.theme.dark" @change="toggle_dark_mode()" inset :label="$t('dark_mode')"></v-switch>
                                        </v-toolbar>
                                    </v-card>





                                </v-list-item-group>
                            </v-list>
                        </v-navigation-drawer>

                    </v-app-bar>

                </v-layout>


        <v-overlay class="related_overlay messiri_fonted" :value="relatedOverlay" color="#f3f3f3" opacity="0.9" :key="index" @click="relatedOverlay = false">

            <!-- <v-icon x-large light @click="relatedOverlay = false">mdi-close-circle</v-icon> -->
            <span @click="relatedOverlay = false" style="content: url(/assets/img/close.svg); width:25px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
            <span class="footer_desc">{{$t('close')}}</span>

            <v-img src="/assets/img/footer_logo2.svg" height="65" class="mx-auto" width="319"></v-img>

            <div class="footer_desc mt-3 text-center"> {{$t('alkafeel_title')}}  - {{ $t('alkafeel_desc') }}</div>

            <v-layout class="messiri_fonted" row mt-10 mb-10 align-center style="padding-right: 22px; text-align:center">
                <div class="overlay_title">
                    <span style="content: url(/assets/img/footer_title_ico.svg); vertical-align: bottom;"></span>
                     المواقع التابعة
                </div>
            </v-layout>

            <template v-for="item in nav">
                <template v-if="item.width > 790">
                    <template v-for="(t, index) in JSON.parse(item.sub)">
                        <v-layout class="messiri_fonted mx-auto" style="max-width: 1440px" row v-if="index%3 == 0" :key="t">
                            <v-flex lg4 v-if="t.visible == 1">
                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>

                                <template v-if="!t.router">
                                    <a :href="t.url" target="_blank" class="related_links">{{ t.title }}</a>
                                </template>
                                <template v-else>
                                    <router-link class="related_links" :to="t.to" >{{ t.title }}</router-link>
                                </template>
                                
                            </v-flex>

                            <v-flex lg4 v-if="JSON.parse(item.sub)[index+1] && JSON.parse(item.sub)[index+1].visible == 1">
                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>

                                <template v-if="!JSON.parse(item.sub)[index+1].router">
                                    <a :href="JSON.parse(item.sub)[index+1].url" target="_blank" class="related_links">{{ JSON.parse(item.sub)[index+1].title }}</a>
                                </template>
                                <template v-else>
                                    <router-link class="related_links" :to="JSON.parse(item.sub)[index+1].to" >{{ JSON.parse(item.sub)[index+1].title }}</router-link>
                                </template>

                            </v-flex>

                            <v-flex lg4 v-if="JSON.parse(item.sub)[index+2] && JSON.parse(item.sub)[index+2].visible == 1">
                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>

                                <template v-if="!JSON.parse(item.sub)[index+2].router">
                                    <a :href="JSON.parse(item.sub)[index+2].url" target="_blank" class="related_links">{{ JSON.parse(item.sub)[index+2].title }}</a>
                                </template>
                                <template v-else>
                                    <router-link class="related_links" :to="JSON.parse(item.sub)[index+2].to" >{{ JSON.parse(item.sub)[index+2].title }}</router-link>
                                </template>

                            </v-flex>
                        </v-layout>
                    </template>
                </template>
            </template>

        </v-overlay>







        <v-overlay absolute :class="searchDesc ? '' : 'search_overlay_result'" class="search_overlay messiri_fonted" :value="searchOverlay" color="#f3f3f3" opacity="0.9" >

            <!-- <v-icon x-large light @click="searchClose">mdi-close-circle</v-icon> -->
            <span @click="searchClose" style="content: url(/assets/img/close.svg); width:25px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
            <span class="footer_desc">{{$t('close')}}</span>

            <v-img src="assets/img/footer_logo2.svg" height="65" class="mx-auto" width="319"></v-img>

            <div class="footer_desc mt-3 text-center"> {{$t('alkafeel_title')}}  - {{ $t('alkafeel_desc') }}</div>

            <v-layout class="messiri_fonted" row mt-6 mb-6 align-center style="text-align:center">
                <div class="overlay_title">
                    <span style="content: url(/assets/img/footer_title_ico.svg); vertical-align: bottom;"></span>
                    {{$t('search')}}
                </div>
            </v-layout>

            <v-layout row>
                <v-text-field v-model="searchTerm" @change="searchChange" class="vghh" style="color: #000 !important;" @keyup.enter="search" solo flat light color="success" :label="$t('action_search')"  hide-details="auto" outlined :rules="SearchRules" >
                <v-btn color="#14A79D" slot="append" @click="search">
                    <!-- <v-icon color="#FFF">mdi-magnify</v-icon> -->
                    <div class="serv" style="padding-top:3px"><svg viewBox="0 0 26 25"><use xlink:href="/assets/img/services/search.svg#view"></use></svg></div>
                </v-btn>
                </v-text-field>
            </v-layout>

            <v-layout row light>
                <div v-if="no_results" style="color: #c00">{{ $t('msg_no_result') }}</div>
            </v-layout>

            
            <template v-if="searchDesc">
                <v-layout row dark mt-10 mb-3 class="overlay_sub_title hidden-sm-and-down">
                    <span style="content: url(/assets/img/footer_title_ico.svg);" class="overlay_sub_ico"></span>
                    <span style="margin:4px 5px">{{$t('search_tags')}}</span>
                </v-layout>
                <v-layout row dark class="hidden-sm-and-down">
                    <template v-for="tag in tg">
                        <router-link :to="'/newscat/'+tag.req+'?lang='+lang"  style="text-decoration: none; padding: 10px"><span style="color: #14A79D">
                             # </span><span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{tag.title}}</span>
                        </router-link>
                    </template>
                </v-layout>

                <v-layout row dark mt-10 mb-3 class="overlay_sub_title">
                    <span style="content: url(/assets/img/footer_title_ico.svg);" class="overlay_sub_ico"></span>
                    <span style="margin:4px 5px">{{$t('search_doors')}}</span>
                </v-layout>
                <v-layout row dark>
                    <template v-for="it in searchDoors">

                        <template v-if="it.to === ''">
                            <a :href="it.url+'?lang='+lang" class="hidden-sm-and-down" style="text-decoration: none; padding: 10px" :key="it">
                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>
                                <span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{$t(it.title)}}</span>
                            </a>
                            <v-flex sm6 xs6 :key="it" class="hidden-md-and-up">
                                <a :href="it.url+'?lang='+lang" style="text-decoration: none; padding: 10px" >
                                    <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>
                                    <span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{$t(it.title)}}</span>
                                </a>
                            </v-flex>
                        </template>
                        <template v-else>
                            <router-link :to="it.to+'?lang='+lang" class="hidden-sm-and-down" style="text-decoration: none; padding: 10px" :key="it">
                                <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>
                                <span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{$t(it.title)}}</span>
                            </router-link>
                            <v-flex sm6 xs6 :key="it" class="hidden-md-and-up">
                                <router-link :to="it.to+'?lang='+lang" style="text-decoration: none; padding: 10px">
                                    <span style="content: url(/assets/img/footer_title_ico.svg);" class="related_sub_item"></span>
                                    <span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{$t(it.title)}}</span>
                                </router-link>
                            </v-flex>
                        </template>
                        
                    </template>
                    

                    
                </v-layout>
            </template>


            <template v-else>
                <template v-if="searchLoading">
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                </template>
                <template v-else v-for="(result, i) in searchResults">
                    <template v-if="i !== 'status'">
                        <template v-if="result[0]">

                            <v-layout row class="search_res mx-auto dirr" :key="result">
                                <v-flex lg2 class="overlay_sub_title">
                                    <v-layout row dark mt-10 mb-3 class="overlay_sub_title">
                                        <span style="content: url(/assets/img/footer_title_ico.svg);" class="overlay_sub_ico"></span>
                                        <span style="margin:4px 5px">{{$t(i)}}</span>
                                    </v-layout>
                                        <div class="btns" :style="dir === 'rtl' ? 'justify-content: right' : 'justify-content: left'">
                                            <div :style="dir === 'rtl' ? 'left: 5%' : 'right: 5%'" class="sira_nav hidden-md-and-down">
                                                <div :id="'lbtn_'+i" :class="dir === 'rtl' ? 'lbtn' : 'rbtn'" :style="dir === 'rtl' ? 'right: 30px' : 'left: 50px'"></div>
                                                <div :id="'rbtn_'+i" :class="dir === 'rtl' ? 'rbtn' : 'lbtn'" :style="dir === 'rtl' ? 'left: 10px' : 'right: 0px'"></div>
                                            </div>
                                        </div>
                                </v-flex>
                                <v-flex lg10>
                                    <swiper :options="{breakpoints: {1461: {width:1050},600: {width:900},300: {width:700,}},slidesPerView: 3,spaceBetween:30, width: 1050, freeMode: true,loop: false, keyboard: true, navigation: {nextEl: '#rbtn_'+i,prevEl: '#lbtn_'+i}}" ref="mySwiper" class="swiper">
                                        <template v-for="(sec, k) in result">
                                            <swiper-slide >
                                                <v-flex v-if="k != 'more'" style="color:#000" :key="sec">
                                                    <template v-if="i === 'title_photos'">
                                                        <a :href="sec.url">
                                                            <div class="test">
                                                                <v-img aspect-ratio="1" :src="sec.image" class="sw_item"></v-img>
                                                            </div>
                                                        </a>
                                                    </template>
                                                    <template v-else-if="i === 'pub'">
                                                        <a :href="sec.url">
                                                            <div style="width: 225px; height: 225px;display: inline-block;position: relative;">
                                                                <v-img aspect-ratio="1" :src="sec.image" class="sw_item"></v-img>
                                                            </div>
                                                        </a>
                                                    </template>
                                                    <template v-else-if="i === 'the_projects' || i === 'index_friday'">
                                                        <v-card flat light :href="sec.url">
                                                            <v-img aspect-ratio="1" max-height="185" :src="sec.image" class="sw_item"></v-img>
                                                            <v-card-subtitle v-if="sec.title != ''" class="search_title" :title="sec.title">{{sec.title.substring(0,70)+".."}}</v-card-subtitle>
                                                        </v-card>
                                                    </template>
                                                    <template v-else>
                                                        <v-card flat light :to="sec.url">
                                                            <v-img aspect-ratio="1" max-height="185" :src="sec.image" class="sw_item">
                                                                <div v-if="i === 'videos'" style="position: absolute;top: 35%;right: 40%;width: 60px;height: 60px;">
                                                                    <img src="/assets/img/play_icon.svg" style="width: 100%;eight: 100%;" />
                                                                </div>
                                                            </v-img>
                                                        
                                                            <v-card-subtitle v-if="sec.title != ''" class="search_title" :title="sec.title">{{sec.title.substring(0,70)+".."}}</v-card-subtitle>
                                                        </v-card>
                                                    </template>
                                                </v-flex>
                                                <v-flex v-else style="color:#000" >
                                                    <v-card max-width="260" light class="text-center search_more">
                                                        <a :href="result.more">{{$t('more')}}</a>
                                                    </v-card>
                                                </v-flex>
                                            </swiper-slide>
                                        </template>
                                        
                                        <swiper-slide></swiper-slide>
                                        <swiper-slide></swiper-slide>
                                    </swiper>
                                </v-flex>
                            </v-layout>


                            <v-layout row :key="result">
                                <div style="text-align: center; width: 100%">
                                    <img src="/assets/img/zack.svg" alt="">
                                </div>
                            </v-layout>



                        </template>
                    </template>
                </template>

            </template>

        </v-overlay>








        <v-overlay absolute class="langs_overlay messiri_fonted" :value="langsOverlay" color="#f3f3f3" opacity="0.9" :key="index">

            <!-- <v-icon x-large light @click="langsOverlay = false">mdi-close-circle</v-icon> -->
            <span @click="langsOverlay = false" style="content: url('/assets/img/close.svg'); width:25px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
            <span class="footer_desc">{{$t('close')}}</span>

            <v-img src="/assets/img/footer_logo2.svg" height="65" class="mx-auto" width="319"></v-img>

            <div class="footer_desc mt-3 mb-14 text-center"> {{$t('alkafeel_title')}}  - {{ $t('alkafeel_desc') }}</div>

            <div class="messiri_fonted text-center" mb-6>
                <template v-for="lang in langs" >
                    <template v-if="lang['display'] == 1">
                        <span v-if="lang['dir'] == 1" class="lang_title" :key="lang" dir="rtl">
                            <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                            <a style="color: #5B5B5B;text-decoration: none; margin:0 10px" :href="'?lang='+lang['code']">{{lang['name']}} </a>
                        </span>
                    </template>
                </template>
            </div>

            <v-layout row class="messiri_fonted text-center" mt-6 mb-6 align-center style="padding-right: 22px; text-align:center">
                <template v-for="lang in langs" >
                    <template v-if="lang['display'] == 1">
                        <v-flex v-if="lang['dir'] == 2" class="lang_title" :key="lang" style="direction:rtl">
                            <a style="color: #5B5B5B;text-decoration: none; margin:0 10px" :href="'?lang='+lang['code']">{{lang['name']}} </a>
                            <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                        </v-flex>
                    </template>
                </template>
            </v-layout>

            <hr style="color:#C4C4C4">

            <!-- <div class="footer_desc mt-10 mb-14 text-center" style="font-size: 20px;">
                <span style="content: url(assets/img/footer_title_ico.svg);" class="related_sub_item"></span>
                <span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{$t('lang_geo')}}</span>
            </div> -->

        </v-overlay>






            
        <!-- </div> -->

        <div class="service_bar" :class="{hide: drawer}" style="left:20px; /*background-color: rgba(0,0,0,.4);*/">


            <div class="service_ico" @click="searchOverlay = !searchOverlay">
                <!-- <v-icon large color="#FFF" :title="$t('action_search')"> mdi-magnify </v-icon> -->
                <div class="serv">
                    <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/services/search.svg#view"></use></svg>
                </div>
                <div class="service_ico_def tl">{{ $t('search') }}</div>
            </div>
            
            <router-link :to="'/stream?lang='+lang" style="text-decoration: none;">
                <!-- <div class="service_ico" style="margin-top:7px" @click="targrtLink('https://alkafeel.net/live/?lang='+lang)"> -->
                <div class="service_ico" style="margin-top:7px" >
                    <div class="serv">
                        <svg viewBox="0 0 23 25"><use xlink:href="/assets/img/services/live.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('index_live') }}</div> 
                </div>
            </router-link>

            <div class="service_ico hidden-sm-and-down" @click="targrtLink('https://alkafeel.net/radio/?lang='+lang)">
                <!-- <v-icon large color="#FFF" :title="$t('radio')"> mdi-radio-tower </v-icon> -->
                <div class="serv">
                    <svg viewBox="-3 0 26 26"><use xlink:href="/assets/img/services/podcast2.svg#view"></use></svg>
                </div>
                <div class="service_ico_def tl">{{ $t('radio') }}</div>
            </div>
            
            <v-menu v-model="prayerMenu" :close-on-content-click="false" :nudge-width="200" offset-x >
                <template v-slot:activator="{ on, attrs }">
                    <div class="service_ico" v-bind="attrs" v-on="on" >
                        <!-- <v-icon large color="#FFF" :title="$t('prayer_times')"> mdi-progress-clock </v-icon> -->
                        <div class="serv">
                            <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/times.svg#view"></use></svg>
                        </div>
                        <div class="service_ico_def tl">{{ $t('prayer_times') }}</div>
                    </div>
                </template>

                <v-card dark>
                    <v-list light>
                        <v-list-item>  
                            <v-list-item-title> {{ $t('index_prayer') }} </v-list-item-title> 
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list dense>
                        <v-list-item v-for="text in azan_text" :key="text" class="messiri_fonted">
                            <v-flex class="prayer_text pa-0" md6>{{text[0]}}:</v-flex>  
                            <v-flex class="prayer_text pa-0" md6 text-left>{{prayer[text[1]]}}</v-flex>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <router-link :to="'/contact?lang='+lang" style="text-decoration: none;">
            <!-- <div class="service_ico" @click="targrtLink('https://alkafeel.net/contact/?lang='+lang)"> -->
                <div class="service_ico" >
                    <div class="serv">
                        <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/contact.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('index_callus') }}</div>
                </div>
            </router-link> 
            <a :href="'/muharram/?lang='+lang+'#visitor'" style="text-decoration: none;">
            <!-- <div class="service_ico" @click="targrtLink('https://alkafeel.net/contact/?lang='+lang)"> -->
                <div class="service_ico" >
                    <div class="serv">
                        <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/location.svg#view"></use></svg>
                        <!-- <svg width="25" height="25" viewBox="0 0 34 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.2422 44.7461C16.0332 45.9766 17.8789 45.9766 18.6699 44.7461C31.502 26.2891 33.875 24.3555 33.875 17.5C33.875 8.18359 26.3164 0.625 17 0.625C7.5957 0.625 0.125 8.18359 0.125 17.5C0.125 24.3555 2.41016 26.2891 15.2422 44.7461ZM17 24.5312C13.0449 24.5312 9.96875 21.4551 9.96875 17.5C9.96875 13.6328 13.0449 10.4688 17 10.4688C20.8672 10.4688 24.0312 13.6328 24.0312 17.5C24.0312 21.4551 20.8672 24.5312 17 24.5312Z" fill="#fff"/>
                </svg> -->
                    </div>
                    <div class="service_ico_def tl">{{ $t('visitor_guide') }}</div>
                </div>
            </a>

        

            <div class="service_ico" style="margin-top:7px" @click="langsOverlay = !langsOverlay">
                <!-- <v-icon large color="#FFF" :title="$t('langs')"> mdi-translate </v-icon> -->
                <div class="serv">
                    <svg viewBox="0 0 25 30"><use xlink:href="/assets/img/services/lang.svg#view"></use></svg>
                </div>
                <div class="service_ico_def tl">{{ $t('langs') }}</div>
            </div>


            <v-btn width="22" class="hidden-md-and-up mx-4 togg_btn" icon @click="toggle_dark_mode()" > 
                <svg v-if="$vuetify.theme.dark" width="22px" viewBox="0 0 15 15" fill="#FFF"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                <svg v-else viewBox="0 0 15 15" width="22px" fill="#FFF"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
            </v-btn>
            <div class="service_ico_def tl hidden-md-and-up">{{$t('dark_mode')}}</div>

        </div>



        



        <v-layout row class="alabbas mx-auto dirr pb-lg-0 d-flex">
            <div class="top-link d-flex">
                <div class="shrine-history">
                    <router-link class="side-title" :to="'/history?lang='+lang">
                        {{$t('index_histroy')}}
                    </router-link>
                </div>
                <div class="abbas-bin-ali">
                    <router-link class="mid-title" :to="'/abbas?lang='+lang">
                        {{$t('index_abbas')}} 
                    </router-link>
                </div>
                <div class="shrine-history">
                    <router-link class="side-title" :to="'/description?lang='+lang">
                        {{$t('index_desc')}}
                    </router-link>
                </div>
            </div>
        </v-layout>

    </v-container>
</template>

<script>

let endpoints = [
'https://alkafeel.net/main_bg/data/nav_main.json?t='+ Date.now(),
'https://alkafeel.net/main_bg/data/bg_main.json?t='+ Date.now(),
'https://alkafeel.net/main_bg/data/core_langs_main.json',
'https://alkafeel.net/main_bg/data/date_main.json?t='+ Date.now(),
// 'abbas-histories/getCats',
'https://alkafeel.net/main_bg/data/bg_gradiant.json?t='+ Date.now(),
'https://alkafeel.net/main_bg/data/news_tags_main.json',
'salaDate'
]
export default {

data() {
    return {
        menuOpen: false,
        drawer: false,
        group: null,
        dialog: false,
        lang: 'ar',
        logo: 'logo6.svg',
        ar_logo: 'logo5.svg',
        lang_src: 'uploads',
        dir : 'rtl',
        dateD: '',
        dateH: '',
        dateH_ico: '',
        dateM: '',

        prayerMenu: false,
        azan_text: [[this.$t("index_fajir"), 'fajer'], [this.$t("index_sunrise"), 'rise'], [this.$t("index_doher"), 'noon'], [this.$t("index_maghrib"), 'ghrob'], [this.$t("midnight"), 'mid']],
        prayer: {"fajer": "00:00","rise": "00:00","noon": "00:00","ghrob": "00:00","mid": "00:00"},

        searchMenu: false,
        sites: [{text: 'index_news', value: 1}, {text: 'videos', value: 2}, {text: 'index_gallery', value: 3}, {text: 'index_projects', value: 4}, {text: 'friday_ser', value: 5}, {text: 'pub', value: 6}],
        

        langsMenu: false,

        relatedOverlay: false,

        searchOverlay: false,
        SearchRules: [value => !!value || this.$t("required_field"), value => (value && value.length >= 3) || this.$t("three_letters"),],
        searchTerm: '',
        searchDesc: true,
        searchResults: [],
        no_results: false,
        searchLoading: true,
        searchDoors: [
            {title:'news', url:'https://alkafeel.net/news/archive', to:'/articles'}, 
            {title:'index_live', url:'https://alkafeel.net/live', to:'/stream'}, 
            {title:'title_photos', url:'https://alkafeel.net/photos/', to:''}, 
            {title:'videos', url:'https://alkafeel.net/videos/', to:'/media'}, 
            {title:'the_projects', url:'https://alkafeel.net/projects/', to:''}, 
            {title:'zyara', url:'https://alkafeel.net/zyara/', to:''}, 
            {title:'pub', url:'https://alkafeel.net/publications/', to:'/lib'}, 
            {title:'history', url:'https://alkafeel.net/history/', to:'/history'}, 
            {title:'description', url:'https://alkafeel.net/description/', to:'/description'}, 
            {title:'msg_message_to_shrine', url:'https://alkafeel.net/message/', to:''}, 
            {title:'index_lost', url:'https://alkafeel.net/lost/', to:''}, 
            {title:'index_panorama', url:'https://alkafeel.net/panorama/', to:''}, 
            {title:'index_audio', url:'https://alkafeel.net/audio/', to:''}],

        langsOverlay: false,

        tags: [],
        tg: [],

        nav: [],
        langs: [],
        date: [],
        abbas: [],

        // gradiant colors
        grad1: 'rgba(68,68,68,.2)',
        grad2: 'rgba(37,40,20,.6)',
        grad3: 'rgba(255,255,255,1)',
        grad3_light: 'rgba(255,255,255,1)',
        grad3_dark:  'rgba(18,18,18,1)',
        nvColor: 'rgba(0,0,0,0.1)',

        pic : 'f2c855db9b43d1b60b8567e2989b38c4.jpg',
        picM: '420/f2c855db9b43d1b60b8567e2989b38c4.jpg',
        
        fbUrl: 'https://facebook.com/alkafeel.global',
        isUrl: 'https://instagram.com/alkafeel.global.network/',
        teUrl: 'https://t.me/alkafeel_global_network',
        ytUrl: 'https://youtube.com/alkafeelnet',
        twUrl: 'https://twitter.com/AlkafeelAbbas',
        tkUrl: 'https://www.tiktok.com/@abbasholyshrine',
        swiperOptions: {
            slidesPerView: "3",
            spaceBetween:30,
            freeMode: true,
           // loop:true,
            loop: false,
            navigation: {
                nextEl: ".rbtn",
                prevEl: ".lbtn",
            },
            breakpoints: {
                1461: {
                    width:1050,
                },
                600: {
                    width:900,
                },
                300: {
                    width:700,
                }
            },
            keyboard: true,
            Observer: true,
            ObserveParents: true,
            lazy: false
        },
        attrs: {
            class: 'mb-6',
            boilerplate: false,
            elevation: 2,
        },
        loading: true,
        dark_theme: 0,

        lang_cover: {'lang':{'tr':''}},
        sch_end: false
    }
},
watch: {
    group () {
        this.group = false
    },
    dark_theme() {
        if (this.$vuetify.theme.dark) { 
            this.ar_logo = 'logo6.svg';
            this.lang_src = 'src/svg';
            this.grad3 = this.grad3_dark;
        } else {
            this.ar_logo = 'logo5.svg';
            this.lang_src = 'uploads';
            this.grad3 = this.grad3_light;
        }
    }
},

directives: {
},
computed: {
    gradiant_style() {
    let style = '';
    if (this.$vuetify.breakpoint.smAndDown) {
        style = {'background-image': 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.2) 85%, rgba(0, 0, 0, 0.4) 100%), url(//alkafeel.net/main_bg/uploads/' + this.picM + ')'};
    } else if (this.$vuetify.breakpoint.xl) {
        style = {'background-image': 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.2) 85%, rgba(0, 0, 0, 0.4) 100%), url(//alkafeel.net/main_bg/uploads/' + this.pic + ')'};
    } else {
        style = {'background-image': 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0.4) 100%), url(//alkafeel.net/main_bg/uploads/' + this.pic + ')'};
    }
    return style;
}




},
created () {
    this.initialize();
},
mounted() {
    document.addEventListener("click", this.closeMenu);
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
        if (theme === "true") {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }
    } else if ( window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    } else {
        this.$vuetify.theme.dark = false;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }

},
beforeDestroy() {
    document.removeEventListener("click", this.closeMenu);
},
methods:{
    closeMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.menuOpen = false;
      }
    },
    toggle_dark_mode () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        this.dark_theme++
    },
    initialize() {
        this.$axios
            // .all([requestNav, requestpic, requestlangs, requestdate, requestabbas, requestgrad, requesttags, requestpraym])
            .all(endpoints.map((endpoint) => this.$axios.get(endpoint)))
            .then(
            this.$axios.spread((...responses) => {
            this.loading = false;
            const responseNav   = responses[0].data;
            const responsePic   = responses[1].data;
            const responseLangs = responses[2].data;
            const responseDate  = responses[3].data;
            // const responseAbbas = responses[4].data;
            const responseGrad  = responses[4].data;
            const responseTags  = responses[5].data;
            const responsePraym = responses[6].data;

            this.nav = responseNav;

            this.pic  = responsePic.pic;
            this.picM = '420/'+responsePic.mob;

            this.lang_cover = responsePic;

            if (responsePic.sch && new Date().getTime() > (responsePic.sch*1000)) {
                this.sch_end = true;
                this.pic  = responsePic.dflt;
                this.picM = '420/'+responsePic.dflt_mob;
            }
            // this.prayer = responsePray[this.getNow()]; // server date fallback
            // this.prayer = responsePraym[responseDate.prayer];
            if (typeof(responsePraym[0]) !== 'undefined') {this.prayer = responsePraym[0]}
            
            this.date = responseDate.langs;
            
            this.langs = responseLangs;

            // this.tags = responseTags;
            this.tg = responseTags.slice(0,12).sort(() => Math.random() - 0.5);

            
            // this.abbas = responseAbbas;

            this.grad1 = responseGrad.grad1;
            this.grad2 = responseGrad.grad2;
            this.grad3_light = responseGrad.grad3;
            this.grad3_dark  = responseGrad.grad3_dark;
            this.grad3 = this.grad3_light;
            
            this.nvColor = responseGrad.nv_color;

            // this.$store.commit('addSocial', this.langs[0]);
            this.$store.commit('addNavigation', responseNav);
            this.$store.commit('addPrayer', this.prayer);
            this.$store.commit('addDate', this.date);

            // console.log(responseGrad.nv_color);
            // console.log(responsePraym[0].noon);
            // console.log(new Date().getTime() > (responsePic.sch*1000) ? new Date().getTime()+' Larger than '+(responsePic.sch*1000) : new Date().getTime()+' Smaller than '+(responsePic.sch*1000));
            // console.log(this.$store.state.social)
            })
        )
        .catch();
    },
    targrtLink(link) {
        return window.open(link,'_blank')
    },
    getNow() {
        const today = new Date();
        const date = today.getDate().toString().padStart(2, "0")+'/'+(today.getMonth()+1).toString().padStart(2, "0");
        return date;
    },
    lang_dt (url) {
        if (url === undefined) {
            this.lang_ck ('ar');
            return;
        }
        this.lang_ck (url)
    },
    lang_ck (code) {
        this.langs.forEach((element, index) => {
            if (element.code === code) {
                this.lang = code;
                this.$i18n.locale=code;
                this.dir = element.dir == '1' ? 'rtl' : 'ltr';
                this.logo = element.logo;

                if (element.fb) {this.fbUrl = element.fb} else {this.langs[index].fb = this.fbUrl}
                if (element.inst) {this.isUrl = element.inst;} else {this.langs[index].inst = this.isUrl}
                if (element.twtr) {this.twUrl = element.twtr;} else {this.langs[index].twtr = this.twUrl}
                if (element.yt) {this.ytUrl = element.yt;} else {this.langs[index].yt = this.ytUrl}
                if (element.tlg) {this.teUrl = element.tlg;} else {this.langs[index].tlg = this.teUrl}
                if (element.tk) {this.tkUrl = element.tk;} else {this.langs[index].tk = this.tkUrl}



                this.$store.commit('addSocial', this.langs[index]);

                this.$store.dispatch("UpdateLang",{lang:this.lang,dir:this.dir});

                this.$vuetify.rtl = this.dir === 'rtl' ? true : false;
                document.title = this.$t('home') + ' - ' + this.$t('alkafeel_title');

                localStorage.setItem("lang",this.$i18n.locale);
                this.$axios.defaults.headers.get['lang']=this.$i18n.locale;
            }
        });
        this.loadDate (code);
        this.loadCover (code)
    },
    loadCover(code) {
        if (code == 'tr' && this.lang_cover.hasOwnProperty('lang')) {
            if (this.lang_cover.lang.hasOwnProperty('tr') && !this.sch_end) {
                this.pic  = 'langs/'+this.lang_cover.lang.tr;
                this.picM = 'langs/420/'+this.lang_cover.lang.tr;
            }
        }
    },
    isLang () {
        if (this.lang !== 'ar') {
            return true;
        }
        return false;
    },
    loadDate (code) {
        try {
            this.dateD = this.date[code].D;
            this.dateH = this.date[code].H !== '' ? this.date[code].H+' <img src="/assets/img/z-ali.svg"> ' : '';
            this.dateH_ico = this.date[code].H;
            this.dateM = this.date[code].M;
        } catch (e) {

        }
        
    },
    search() {
        var params = new URLSearchParams();
        params.append('search_term', this.searchTerm);
        params.append('lang', this.lang);
        this.$axios.post('https://alkafeel.net/Core/API/Search.php', params).then(response => {
            if (response.data.status === 'OK') {
                this.searchDesc = false
            } else {
                this.no_results = true
            }
            this.searchLoading = false;
            this.searchResults = response.data
        })
    },
    searchChange () {
        this.no_results = false;
        // this.searchDesc = true
    },
    searchClose() {
        this.searchOverlay = false;
        this.no_results = false;
        this.searchDesc = true;
        this.searchTerm = '';
    },
    searchTags () {
        this.tg = this.tags.slice(0,12).sort(() => Math.random() - 0.5);
    }
},
updated() {
    this.lang_dt(this.$route.query.lang);

    if (this.$vuetify.theme.dark) { 
        this.ar_logo = 'logo6.svg';
        this.lang_src = 'src/svg';
        this.grad3 = this.grad3_dark;
    }
},
}
</script>
<style>
.vghh input{
color:  #000 !important;
}
.messiri_fonted * {
    font-family: 'readex';
    font-size: 14px;
    font-weight: 400;
}
</style>
<style scoped>
.togg_btn {
    transition: opacity 1s ease-in-out;
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
    opacity:10 !important;
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content{
    opacity:10 !important;
}
.drawer {
    position: absolute;
    top:0!important;
    right:0;
    left:0;
    width:100%!important;
    bottom: 0;
}
.navbar {
    top: 52px!important;
    /* top: 139px!important; */
    height: auto!important;
    transition: .3s ease-in-out all;
    border-radius: 0 0 4px 4px !important;
    padding: 10px;
    z-index: 99999!important;
}

/* @media (min-width: 1264px) and (max-width: 1830px) {

.navbar {
    top: 152px !important;

}} */

.navbar_m {
    top: 52px!important; 
    transition: .3s ease-in-out all;
    z-index: 99999!important;
}
.navbar_m_active {
    top: 0px!important; 
    z-index: 99999!important;
}
.navMColor {
    color:#5E5E5E
}
.navSize {
    font-size: 18px;
    text-decoration: none;
}
.v-list-group--active .v-list-group__header__append-icon span {
    transform: rotate(180deg);
}
.v-overlay {
    z-index: 999999!important;
}
@media (max-width: 991px) and (orientation: landscape) {
    /* .service_bar {
        display: none;
    } */
}
.v-app-bar--is-scrolled {
    top: 0!important;
    background-color: rgba(111, 124, 38, 0.8)!important;
    color: black!important;
}
.navbar_m.v-app-bar--is-scrolled {
    top: 0px!important;
    background-color: rgba(111, 124, 38, 0.8)!important;
}
.v-app-bar--is-scrolled .v-image__image {
    /* background-image: url('/public/assets/logo6.svg')!important; */
    background-color: black;
    display: none!important;
}
.sp {
    letter-spacing: normal;
}
.related_sub_item {
    width:12px; 
    height: 12px; 
    display: ruby-text-container;
    margin: 0 5px;
}
.related_links {
    color:#5B5B5B; 
    text-decoration: none;
    font-weight: 500;
    opacity: .9;
    font-size: 16px;
    line-height: 40px;
}
.related_overlay >>> .v-overlay__content,  .search_overlay >>> .v-overlay__content,  .langs_overlay >>> .v-overlay__content{
    width: 80%;
}
.search_overlay_result {
    position: absolute!important;
    bottom: unset!important;
    padding-bottom: 300px;
}
.search_more {
    padding-top:5%
}
.overlay_sub_title {
    font-weight: bold;
    color: #5B5B5B;
}
.overlay_sub_ico {
    height: 32px;
    display: ruby-text-container;
    margin: 0 5px; 
}
.overlay_title {
    color: #5B5B5B; 
    vertical-align: top; 
    line-height: 40px; 
    font-size:30px; 
    width:100%
}
.lang_title {
    color: #5B5B5B; 
    vertical-align: top; 
    line-height: 40px; 
    font-size:30px;
    margin: 25px;
    min-width: 160px;
    display: inline-block;
}
@media (max-width: 600px) {
    .lang_title {
        vertical-align: top; 
        line-height: 20px; 
        font-size:20px;
        margin: 5px;
        min-width: 80px;
        display: inline-block;
    }
    .lang_title span {
        width: 18px;
    }
    .lang_title a {
        vertical-align: super;
    }
}
.footer_desc {
    color: #5B5B5B;
    font-size: 14px;
}
.abbas_margin {
    margin-bottom: 0 !important;
}
.abbas_space {
    margin-bottom: 10px;
}
.abbas_max {
    max-width: 1460px!important
}
.alabbas {
    min-width: 250px;
    padding: 10px;
    position:absolute;
    bottom: 42px;
    left: 0;
    right: 0;
    z-index: 1;
    max-width: 1461px;
    justify-content: center;
    color: #fff;
}
.alabbas .desc {
    padding: 25px 20px 0 20px;
    vertical-align: bottom;
}

.search_res {
    min-width: 250px;
    padding: 10px;
    /* position:absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    max-width: 1461px */
}
.search_res .desc {
    padding: 25px 20px 0 20px;
    vertical-align: bottom;
}
.search_title {
    font-size:12px; 
    min-height: 46px;
}

.swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide img {
    max-height: 210px;
}
.sw_item {
    vertical-align: bottom;
    border-radius: 5px;
}
.gradiant_img {
    background-image: linear-gradient(to top, #0C0F12 0%, transparent 72px);
}
.sw_img_title {
    color: white;
    font-size: 23px;
    font-weight: 700;
    position: absolute;
    bottom: 10px;
    left:0;
    right:0;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
}
.btns {
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}
.rbtn:hover, .lbtn:hover {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAaBAMAAAD/DukuAAAAG1BMVEUAAACxvVKvt1CxvVKvu1CvvVCxvVKxvFGxvVI20mbEAAAACHRSTlMAzxDfIDDvz9OlLXwAAABCSURBVCjPYwADpgAGPEAxFY8kk0RbAB6tHR0FeLR2NDvg02oy1LTiBA0MEuTL4jcZ6iryfDSoNQvgT3VkpFj8qR0ACz5Vy5nXyccAAAAASUVORK5CYII=");
}
.rbtn, .lbtn {
    position: absolute;
    width: 57px;
    height: 26px;
    cursor: pointer;
    margin: 0 15px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAaBAMAAAD/DukuAAAAGFBMVEUAAACQorWQorWQorWQorWQorWQorWQorUXnEqaAAAAB3RSTlMAzxDfIDDvfLP3jgAAAEJJREFUKM9jAAOmAAY8QDEVjySTeFkAHq3l5QJ4tJYXO+DTajLUtOIEBQzi5MviNxnqKvJ8NKg1C+BPdWSkWPypHQA1cEu7zTSyNwAAAABJRU5ErkJggg==");
}
.lbtn {
    transform: matrix(-1, 0, 0, 1, 0, 0);
    transform: translate(180deg);
}
.sira_nav {
    position: absolute;
    width: 200px;
}
.lang_text * {
    font-weight: normal;
    letter-spacing: normal;
}
.textspace {
    letter-spacing: normal;
}
.text-light {
    color: #FFF;
}
.nav_btn * {
    color: #FFF !important;
    font-size: 15px;
    text-decoration: none;
}
@media (max-width: 1155px) {
    .nav_btn * {
       font-size: 12px; 
    }
}
@media (max-width: 1050px) {
    .nav_btn {
        padding: 0 10px!important;
    }
}
.nav_btn {
    box-shadow: none;
    padding: 0 16px;
}
.nav_btn:hover * {
    color: #B1BD52 !important;
}
.v-btn--active::before {
    opacity: 0!important;
}
.prayer_text {
    font-size: 12px;
}
.service_ico_def {
    color: #FFF;
    font-size: 10px;
}
.service_ico:hover .service_ico_def{
    color: #B1BD52 !important;
}
.serv {
    fill:#FFF;
    width: 25px;
    height: 25px;
    margin: 0 auto 5px;
}
.service_ico:hover .serv{
    fill: #B1BD52;
}
.sub_nav {
    text-decoration: none;
    font-size: 14px;
}
.sub_nav_m {
    text-decoration: none;
    font-size: 15px;
}
.hide {
    display: none;
}
.list_height {
    min-height: 35px;
}
.service_bar {
    position: absolute;
    top: 25%;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}
.service_ico {
    width: 60px;
    text-align: center;
    cursor: pointer;
    padding-bottom: 8px;
    z-index: 5;
}
.service_ico img {
    max-width: 50px;
}
.main_bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    position: relative; 
}
.alignment {
    background-position: right center!important;
}
.border {
    border-bottom: solid thin #c4c4c440;
}
.nav_opacity {
    /* background-color: rgba(0,0,0,.6); */
    color: #FFF;
}
.height {
    width: 100%;
    height: 100%;
}
.alkafeel_eitaa_icon,.alkafeel_facebook_icon,.alkafeel_twitter_icon,.alkafeel_telegram_icon,.alkafeel_instagram_icon,.alkafeel_g-plus_icon,.alkafeel_y-tube_icon, .alkafeel_tiktok_icon{
    fill:#FFF;margin:5px 5px;width:25px;height:25px;border-radius:50%;cursor:pointer;animation:all .3s ease;text-align:center;display:inline-block;padding:0 5px
}
@media (max-width: 640px) {
    .alkafeel_eitaa_icon,.alkafeel_facebook_icon,.alkafeel_twitter_icon,.alkafeel_telegram_icon,.alkafeel_instagram_icon,.alkafeel_g-plus_icon,.alkafeel_y-tube_icon, .alkafeel_tiktok_icon{
        margin:5px 3px;width:22px;height:24px;border-radius:50%;padding:0 4px
    }
}
.alkafeel_eitaa_icon:hover {
    fill: #EF7F1A;
}
.alkafeel_facebook_icon:hover{
  fill:#4862a3
}        
.alkafeel_twitter_icon:hover{
    fill:#ccc
}
.alkafeel_telegram_icon:hover{
    fill:#61a8de
}
.alkafeel_instagram_icon:hover{
    fill:#FFF
}
.alkafeel_y-tube_icon:hover{
    fill:hsl(0,100%,50%)
}
.alkafeel_tiktok_icon:hover{
    fill:rgb(46, 46, 46)
}

.abbas-bin-ali {
    position: relative;
    display: inline-block;
}

.abbas-bin-ali::before,
.abbas-bin-ali::after {
    content: '';
    display: inline-block;
    width: 16px; 
    height: 16px;
    background-image: url('/assets/img/object.svg'); 
    background-size: cover;
    vertical-align: middle;
}

.abbas-bin-ali::before {
    margin-right: 10px; 
    margin-left: 10px; 

}

.abbas-bin-ali::after {
    margin-left: 10px; 
    margin-right: 10px; 

}

.mid-title {
    font-size: 22px;
    color: #fff;
    text-decoration: none;
}

@media (max-width: 767px) { .mid-title { font-size: 16px;} .side-title { font-size: 13px; }}
.side-title { 
    font-size: 16px;
    color: #e0e0e0;
    transition: font-size 0.3s ease, color 0.3s ease; /* أضف هذا السطر */
}

.side-title:hover { 
    font-size: 20px;
    color: #fff;
}




.top-link { 
    align-items: baseline;
}

@media (min-width: 320px) and (max-width: 767px) {
  
    .side-title { 
    font-size: 13px;
}

.mid-title {
    font-size: 16px;
}
  
}

</style>

